<template>
  <div class="categories-page">
    <div class="container">
      <h2
        class="text text--xs-header-3 text--xs-center text--sm-header-2 text--sm-strong text--sm-mt-1 text--sm-mb-2"
      >
        Kategorie zdjęć
      </h2>

      <Categories :loading="loading" :categories="categories" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Categories from '../categories/categories.vue';

export default {
  name: 'CategoriesPage',
  components: {
    Categories,
  },
  computed: {
    ...mapGetters('categories', ['categories', 'loading']),
  },
  metaInfo: {
    title: 'Kategorie zdjęć',
  },
  async created() {
    await this.fetchAllCategories();
  },
  methods: {
    ...mapActions('categories', ['fetchAllCategories']),
  },
};
</script>
